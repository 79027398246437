import React, { useState } from "react"
import PropTypes from 'prop-types'

import ImageMakeCall from './ImageMakeCall'
import ImageScreenpop from './ImageScreenpop'

const ContactSearchResult = (props) => {

    const ImageUrl = "https://downloadcloudcti.blob.core.windows.net/files/WebRCT/Applications/" + props.Application + ".png"

    let CaptionLine2 = ""
    if (props.Organization && (props.Organization !== props.Name))
    {
        CaptionLine2 = props.Organization 
    }

    return (
        <table className="contact" width="100%">
        <tbody>
            <tr>
                <td width="40">{props.Application === "" ? <table width="32" height="32" className="unknowncrm"><tbody><tr><td align="center" valign="center">{props.Name ? props.Name[0] : "?"}</td></tr></tbody></table>  : <img alt="" src={ImageUrl} className="applicationpng" width="32" height="32"/>}</td>
                <td width="4"/>
                <td width="*" align="left">{props.Name ? props.Name : props.Number}
                {CaptionLine2 ? <br/> : <span/>}
                {CaptionLine2 ? <span>{CaptionLine2}</span> : <span/>}
                </td>
                <td width="10"/>
                <td width="18">{(props.Scripts !== null) && (props.Scripts !== undefined) && props.Scripts && (props.Scripts.length > 0) ? <ImageScreenpop ContactName={props.Name} ScriptName={props.Scripts[0].Name ? props.Scripts[0].Name : ""} ScreenpopUrl={props.Scripts[0].ScreenpopUrl ? props.Scripts[0].ScreenpopUrl : ""} TeamsTheme={props.TeamsTheme}/> : <span/>}</td>
                <td width="10"/>
                <td width="18"><ImageMakeCall ContactName={props.Name} ContactNumber={props.Number} MakeCallEvent={() => props.MakeCallEvent(props.Number, props.Name, props.Application, props.Scripts)}/></td>
                <td width="10"/>
            </tr>
        </tbody>
        </table>
    )
}

ContactSearchResult.propTypes = {
    Number: PropTypes.string,
    Name: PropTypes.string,
    Organization: PropTypes.string,
    Application: PropTypes.string,
    Scripts: PropTypes.array,
    TenantGuid: PropTypes.string,
    TeamsTheme: PropTypes.object,
    MakeCallEvent: PropTypes.func
  }

export default ContactSearchResult;