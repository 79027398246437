import axios from "axios"
import { Log } from "./LogApi"

const cTeamsISSBaseApi = "https://teamsservercloudcti-app.azurewebsites.net/"

export async function TeamsISSApiSubscribe(aMicrosoftToken, aCctiEmail) 
{
    try
    {
        const getResponse = await axios.get(cTeamsISSBaseApi + "Auth/" + aMicrosoftToken + "?cctiemail=" + aCctiEmail)
        Log("TeamsISSApiSubscribe response: " + JSON.stringify(getResponse))
        
        return getResponse.status
    }    
    catch (error) 
    {
      return error       
    }
}

export async function TeamsISSApiLogout(aMicrosoftToken) 
{
    try
    {
      await axios.get(cTeamsISSBaseApi + "Logout/" + aMicrosoftToken)
    }  
    catch (error) 
    {
      return error       
    }
}